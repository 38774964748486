@tailwind base;
@tailwind components;
@tailwind utilities;

/* Background Color Transitions */

/* Light mode transition */
@keyframes light1 {
  from {
    background-color: #fff; /* Starting color for light mode */
  }
  to {
    background-color: #e2e8f0; /* Ending color for light mode */
  }
}
@keyframes light2 {
  from {
    background-color: #fff; /* Starting color for light mode */
  }
  to {
    background-color: #cbd5e1; /* Ending color for light mode */
  }
}
@keyframes light3 {
  from {
    background-color: #fff; /* Starting color for light mode */
  }
  to {
    background-color: #94a3b8; /* Ending color for light mode */
  }
}

/* Dark mode transition */
@keyframes dark1 {
  from {
    background-color: #212121; /* Starting color for dark mode */
  }
  to {
    background-color: #475569; /* Ending color for dark mode */
  }
}
@keyframes dark2 {
  from {
    background-color: #212121; /* Starting color for dark mode */
  }
  to {
    background-color: #334155; /* Ending color for dark mode */
  }
}
@keyframes dark3 {
  from {
    background-color: #212121; /* Starting color for dark mode */
  }
  to {
    background-color: #1e293b; /* Ending color for dark mode */
  }
}

/* Apply light mode animation by default */
.animate-transition1 {
  animation: light1 1s ease-in forwards;
}
.animate-transition2 {
  animation: light2 1s ease-in forwards;
}
.animate-transition3 {
  animation: light3 1s ease-in forwards;
}

/* Override with dark mode animation when dark class is present */
.dark .animate-transition1 {
  animation: dark1 1s ease-in forwards;
}
.dark .animate-transition2 {
  animation: dark2 1s ease-in forwards;
}
.dark .animate-transition3 {
  animation: dark3 1s ease-in forwards;
}

/* Carousel Colors */

.slick-prev:before,
.slick-next:before {
  color: #6B6B6B!important;
}

.slick-dots li button:before {
  color: #6B6B6B!important;
}

/* Fades */

.fade-in {
  animation: fadein 3s;
  -moz-animation: fadein 3s; /* Firefox */
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -o-animation: fadein 3s; /* Opera */
}

.fade-in-2 {
  animation: fadein 3s;
  animation-delay: 500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  animation: fadein 3s;
  animation-delay: 1s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:1s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:1s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  animation: fadein 3s;
  animation-delay: 1500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:1500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:1500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  animation: fadein 3s;
  animation-delay: 2s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:2s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:2s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  animation: fadein 3s;
  animation-delay: 2500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:2500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:2500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-7 {
  animation: fadein 3s;
  animation-delay: 3s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:3s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:3s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-8 {
  animation: fadein 3s;
  animation-delay: 3500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:3500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:3500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-9 {
  animation: fadein 3s;
  animation-delay: 4s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:4s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:4s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-10 {
  animation: fadein 3s;
  animation-delay: 4500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:4500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:4500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-11 {
  animation: fadein 3s;
  animation-delay: 5s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:5s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:5s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-12 {
  animation: fadein 3s;
  animation-delay: 6s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:6s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:6s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-13 {
  animation: fadein 3s;
  animation-delay: 6500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:6500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:6500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-14 {
  animation: fadein 3s;
  animation-delay: 7s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:7s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:7s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-15 {
  animation: fadein 3s;
  animation-delay: 7500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:7500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:7500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-16 {
  animation: fadein 3s;
  animation-delay: 8s;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:8s;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:8s;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

.fade-in-17 {
  animation: fadein 3s;
  animation-delay: 8500ms;
  -moz-animation: fadein 3s; /* Firefox */
  -moz-animation-delay:8500ms;
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -webkit-animation-delay:8500ms;
  -o-animation: fadein 3s; /* Opera */
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
